<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('student_program_graduation_success_title')"
                    :isNewButton="false"
                    :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div
                            class="mb-1"
                            v-for="(column, key) in datatable.columns"
                            :key="key"
                        >
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('student_program_graduation_success_title')"
                    :isNewButton="false"
                    :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div
                            class="mb-1"
                            v-for="(column, key) in datatable.columns"
                            :key="key"
                        >
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                :filter-button="false"
                :export-excel="true"
                @exportExcel="downloadExcel"
                @filterClear="filterClear"
            >
                <ValidationObserver ref="form">
                    <b-row>
                        <b-col sm="12" md="6" lg="3">
                            <ValidationProvider
                                name="academic_year"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <b-form-group :label="$t('academic_year')">
                                    <academic-years-selectbox
                                        v-model="
                                            datatable.queryParams.filter
                                                .academic_year
                                        "
                                        :validate-error="errors[0]"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- <b-col sm="12" md="6" lg="3">
                            <ValidationProvider name="semester"
                                                rules="required"
                                                v-slot="{errors}">
                                <b-form-group :label="$t('semester')">
                                    <parameter-selectbox code="semester"
                                                         :validate-error="errors[0]"
                                                         v-model="datatable.queryParams.filter.semester"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> -->
                        <b-col sm="12" md="6" lg="3">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox
                                    size="sm"
                                    v-model="
                                        datatable.queryParams.filter
                                            .faculty_code
                                    "
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="6" lg="3">
                            <b-form-group :label="$t('department')">
                                <department-selectbox
                                    size="sm"
                                    v-model="
                                        datatable.queryParams.filter
                                            .department_code
                                    "
                                    :faculty_code="
                                        datatable.queryParams.filter
                                            .faculty_code
                                    "
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="6" lg="3">
                            <b-form-group :label="$t('program')">
                                <program-selectbox
                                    size="sm"
                                    v-model="
                                        datatable.queryParams.filter
                                            .program_code
                                    "
                                    :faculty_code="
                                        datatable.queryParams.filter
                                            .faculty_code
                                    "
                                    :department_code="
                                        datatable.queryParams.filter
                                            .department_code
                                    "
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="6" lg="3">
                            <ValidationProvider
                                name="program_level"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <b-form-group :label="$t('program_level')">
                                    <ProgramLevelDoubleSelectbox
                                        v-model="
                                            datatable.queryParams.filter
                                                .program_level
                                        "
                                        code="program_levels"
                                        size="sm"
                                        :validate-error="errors[0]"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="12" md="6" lg="3">
                            <b-form-group :label="$t('class')">
                                <parameter-selectbox
                                    v-model="datatable.queryParams.filter.class"
                                    code="graduation_success_classes"
                                />
                            </b-form-group>
                        </b-col>
                        <!-- <b-col sm="12" md="6" lg="3">
                            <ValidationProvider name="is_graduate"
                                                rules="required"
                                                v-slot="{errors}">
                                <b-form-group :label="$t('graduation_status')">
                                    <yes-no-selectbox v-model="datatable.queryParams.filter.is_graduate"
                                                      :values="[1, 2]"
                                                      :validate-error="errors[0]"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> -->
                        <!-- <b-col sm="12">
                            <b-form-group :label="$t('order')">
                                <div class="border rounded p-3">
                                    <b-form-radio v-model="datatable.queryParams.sort"
                                                  value="gpa">
                                        {{ $t('sort_by_semester_grade_point_average') }}
                                    </b-form-radio>
                                    <b-form-radio v-model="datatable.queryParams.sort"
                                                  value="cgpa">
                                        {{ $t('sort_by_graduation_grade_point_average') }}
                                    </b-form-radio>
                                </div>
                            </b-form-group>
                        </b-col> -->
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import DatatableFilter from "@/components/datatable/DatatableFilter";

// Component
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramLevelDoubleSelectbox from "@/components/interactive-fields/ProgramLevelDoubleSelectbox";
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox";
import { ValidationObserver, ValidationProvider } from "vee-validate";

// Services
import StudentProgramService from "@/services/StudentProgramService";

// Other
import qs from "qs";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,

        AcademicYearsSelectbox,
        DepartmentSelectbox,
        FacultySelectbox,
        ParameterSelectbox,
        ProgramLevelDoubleSelectbox,
        ValidationObserver,
        ValidationProvider,
        YesNoSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t("student_program_graduation_success_title"),
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.toUpperCase("edit"),
                                class: "ri-search-eye-line align-middle top-minus-1 mr-3 text-muted",
                                permission: "studentprogram_graduatesuccess",
                                callback: (row) => {
                                    this.showModal("update", row.id);
                                },
                            },
                            {
                                text: this.toUpperCase("delete"),
                                class: "ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted",
                                permission: "studentprogram_graduatesuccess",
                                callback: (row) => {
                                    this.formDelete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.toUpperCase("id"),
                        field: "id",
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase("university_rank"),
                        field: "university_rank",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("faculty_rank"),
                        field: "faculty_rank",
                        hidden: false,
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: "student_number",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: "name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: "surname",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("faculty"),
                        field: "faculty_name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("program"),
                        field: "program_name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("program_level"),
                        field: "program_level",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("semester"),
                        field: "semester",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("academic_year"),
                        field: "academic_year",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("irregular_status"),
                        field: "irregular_status",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("birthdate"),
                        field: "birthdate",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("class"),
                        field: "class",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("registration_type"),
                        field: "registration_type",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("registration_year"),
                        field: "registration_year",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("registration_semester"),
                        field: "registration_season",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("semester_count"),
                        field: "semester_count",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("gpa"),
                        field: "gpa",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("cgpa"),
                        field: "cgpa",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("graduate_detail_cpga"),
                        field: "graduate_detail_cpga",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("placement_score"),
                        field: "placement_score",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("s_grade_conversion"),
                        field: "s_grade_conversion",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("total_ects_credits_attempted"),
                        field: "total_ects_credits_attempted",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("extend"),
                        field: "extend",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("is_graduate"),
                        field: "is_graduate",
                        hidden: false,
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "gpa",
                    page: 1,
                    limit: 20,
                },
            },
            formProcess: null,
            formId: null,
            form: {},
        };
    },
    created() {
        this.filterSet();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {
                academic_year: null,
                semester: null,
                faculty_code: null,
                department_code: null,
                program_code: null,
                program_level: null,
                class: null,
                is_graduate: null,
                sort: "gpa",
            };
        },
        filterClear() {
            this.filterSet();
        },
        async downloadExcel() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                if (this.datatable.queryParams.filter.program_level) {
                    this.datatable.queryParams.filter.program_level =
                        this.datatable.queryParams.filter.program_level.split(
                            ","
                        );
                }
                const config = {
                    params: {
                        ...this.datatable.queryParams.filter,
                    },
                    paramsSerializer: (params) =>
                        qs.stringify(params, { encode: false }),
                };

                if (config.params.is_graduate == "E") {
                    config.params.is_graduate = 1;
                } else if (config.params.is_graduate == "H") {
                    config.params.is_graduate = 0;
                }

                StudentProgramService.excelGraduationSuccess(config)
                    .then((response) => {
                        this.$toast.success(
                            this.$t("api." + response.data.message)
                        );
                        this.$router.push(
                            "/job/logs/detail/" + response.data.data.log_id
                        );
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    });
            }
        },
    },
};
</script>
